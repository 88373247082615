export const environment = {
  endpoint: {
    identityKey: 'WlVFcVVfQXBlS0NBZ003SHo3RzhwYk1RRUhvYTp4OW5XVDVURWViZ0d5Zkg5aW12Z2VSZkdZS3Nh',
    url: 'https://www42.siaf.co.ao'
  },
  contentManager: {
    rootFolder: '13433e96-38ac-4eb7-9294-36dd8c96cfe2'
  },
  type: 'TEST',
  privacyPoliceUrl: 'https://tst.mypharma.co.ao/terms-conditions',
  contentManagerUrl: '/alfresco/nodes/#IMAGE_ID#/content/',
  contentManagerRootUrl: '/alfresco',
  scopes: 'openid '
};
